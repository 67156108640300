import styled from "styled-components"

export const TitleContainer = styled.p`
  margin: 0;
  margin-top: 55px;
  margin-bottom: 48px;
  color: #333333;
  font-weight: bold;
  font-size: 24px;
  letter-spacing: 8px;
`

export const ForTeachersContainer = styled.div`
  width: 100%;
  height: 692px;
  background-color: ${props => props.color};
  display: flex;
  flex-direction: column;
  align-items: center;
  height: auto;

  .Image {
    height: 141px;
  }

  .ListItemContainer {
    p {
      margin: 0;
    }

    margin-bottom: 64px;
  }

  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
      width: 50%;
    }
`}
`

export const ForParentsContainer = styled.div`
  width: 100%;
  height: 692px;
  display: flex;
  flex-direction: column;
  align-items: center;

  .Image {
    height: 141px;
  }

  .ListItemContainer {
    p {
      margin: 0;
    }

    margin-bottom: 64px;
  }

  ${({ theme }) => `
${theme.breakPoints.tablet} {
  width: 50%;
}
`}
`

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;

  ${({ theme }) => `
${theme.breakPoints.tablet} {
  flex-direction: row;
  width: 89%;
}
`}
`
