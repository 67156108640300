import styled from "styled-components"
import { Link } from "gatsby"

export const Navbar = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 80%;
  height: 40px;
  background-color: #f8f8f8;
  margin: 0 auto;
  margin-top: 11px;
  display: none;

  ${({ theme }) => `
${theme.breakPoints.xLargePhone} {
display: flex;
}
`}
`

export const LinkStyled = styled(Link)`
  font-size: 16px;
  font-weight: bold;
  color: ${props => (props.active ? "#0086D8" : "#333333")};
  border-bottom: ${props => (props.active ? "1px solid #0086D8" : "#333333")};
`

export const Item = styled.div`
  margin-right: 120px;
`
