import React from "react"
import ListItem from "../ListItem/ListItem"
import {
  TitleContainer,
  ForTeachersContainer,
  ForParentsContainer,
  Container,
} from "./styles"

const FeatureListContainer = ({ color, itemsForTeachers, itemsForParents,  }) => {
  return (
    <div>
      <Container>
        <ForTeachersContainer color={color}>
          <TitleContainer>FOR TEACHERS</TitleContainer>
          {itemsForTeachers.map(item => (
            <ListItem
              title={item.title}
              description={item.description}
              image={item.image}
            />
          ))}
        </ForTeachersContainer>

        <ForParentsContainer>
          <TitleContainer>FOR PARENTS</TitleContainer>
          {itemsForParents.map(item => (
            <ListItem
              title={item.title}
              description={item.description}
              image={item.image}
            />
          ))}
        </ForParentsContainer>
      </Container>
    </div>
  )
}

export default FeatureListContainer
