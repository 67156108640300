import React from "react"
import { Navbar, LinkStyled, Item } from "./style"

const VIEWS = {
  CALENDAR_VIEW: "CalendarView",
  MESSAGES_VIEW: "MessagesView",
  CATALOG_VIEW: "CatalogView",
  RESOURCES_VIEW: "ResourcesView",
  ACTIVITY_VIEW: "ActivityPlanView",
}

const NavbarFeature = ({ view }) => {
  return (
    <Navbar>
      <Item>
        <LinkStyled
          to="/features/calendar"
          active={VIEWS.CALENDAR_VIEW === view}
        >
          Calendar
        </LinkStyled>
      </Item>

      <Item>
        <LinkStyled
          to="/features/messages"
          active={VIEWS.MESSAGES_VIEW === view}
        >
          Messages
        </LinkStyled>
      </Item>

      <Item>
        <LinkStyled to="/features/catalog" active={VIEWS.CATALOG_VIEW === view}>
          Catalog
        </LinkStyled>
      </Item>

      <Item>
        <LinkStyled
          to="/features/resources"
          active={VIEWS.RESOURCES_VIEW === view}
        >
          Resources
        </LinkStyled>
      </Item>

      <Item>
        <LinkStyled
          to="/features/activityPlan"
          active={VIEWS.ACTIVITY_VIEW === view}
        >
          Activity Plan
        </LinkStyled>
      </Item>
    </Navbar>
  )
}

export default NavbarFeature
