import React from 'react';
import { ThemeProvider } from 'styled-components';
import Layout from 'components/LandingLayout';
import metaTranslations from '../../lib/utils';
import SEO from 'components/seo';
import theme from '../../themes';
import NavbarComponent from '../../components/Landing/Navbar/Navbar';
import NavbarFeature from '../../components/Landing/NavbarFeature/NavbarFeature';
import { windowGlobal } from '../../config';
import FeatureHero from '../../components/Landing/FeatureHero/FeatureHero';
import FeatureListContainer from "../../components/Landing/FeatureListContainer/FeatureListContainer";

import DAPOneClick from '../../images/landing/DAP_1.svg';
import HomeActivities from '../../images/landing/DAP_2.svg';
import AlgorithmActivities from '../../images/landing/DAP_3.svg';
import AssessmentTools from '../../images/landing/DAP_4.svg';
import ModifyActivities from '../../images/landing/DAP_5.svg';
import DAPUpdateMilestones from '../../images/landing/DAP_6.svg';

import DAPHero from '../../images/landing/dap_hero_dsktp.png';
import DAPHeroMobile from '../../images/landing/dap_hero_mobile.png';
import { WithTrans } from '../../language/withTrans';

const FEATURE_VIEW = 'ActivityPlanView';
const COLOR_DAP = "rgb(249,152,72,0.1)";
const HERO_TITLE = "Automated lesson planning";

const FOR_TEACHERS_ARRAY = [
  {
    title: "One-click activity planning",
    description: "One-click activity planning",
    image: DAPOneClick,
  },
  {
    title: "Take-home",
    description: "Take-home activity plans",
    image: HomeActivities,
  },
  {
    title: "Create optimal plan",
    description: "Our proprietary algorithm will create your optimal plan",
    image: AlgorithmActivities
  },
  {
    title: "Trusted assessment",
    description: "Trusted assessment tools",
    image: AssessmentTools,
  },
  {
    title: "Modify daily activity plan",
    description: "Modify your daily activity plan to your needs with our own activities",
    image: ModifyActivities,
  }
];

const FOR_PARENTS_ARRAY = [
  {
    title: "Update milestones",
    description: "Update your kids milestones as needed",
    image: DAPUpdateMilestones,
  },
  {
    title: "Activities for every day",
    description: "Get activities for every day of the week",
    image: DAPOneClick,
  },
];

const ActivityPlan = ({ lang }) => {
  windowGlobal.localStorage.setItem('LANDING_LOCALE', lang);

  return (
    <Layout>
      <SEO
        title={metaTranslations[lang].title}
        description={metaTranslations[lang].description}
        meta={[{ name: 'og:image', content: 'https://kinedu-media.s3.amazonaws.com/classrooms_by_kinedu_2.jpg' }]}
      />
      <ThemeProvider theme={theme}>
        <NavbarComponent lang={'es'} />
        <NavbarFeature view={FEATURE_VIEW} />
        <div>
          <FeatureHero
            title={HERO_TITLE}
            imageDesktop={DAPHero}
            imageMobile={DAPHeroMobile}
          />
        </div>

        <FeatureListContainer
            color={COLOR_DAP}
            itemsForTeachers={FOR_TEACHERS_ARRAY}
            itemsForParents={FOR_PARENTS_ARRAY}
          />
      </ThemeProvider>
    </Layout>
  );
};

export default WithTrans()(ActivityPlan);
