import React from 'react';
import styled from 'styled-components';

const MainContainer = styled.div`
  width: 100%;
  text-align: center;
  ${({ theme }) => `
    ${theme.breakPoints.tablet} {
     width: 89%;
     margin: 0 auto;
    }
`}
`;

const Title = styled.p`
  font-size: 20px;
  color: #3A3A3A;
  font-family: "Proxima Nova";
  ${({ theme }) => `
  ${theme.breakPoints.tablet} {
   font-size: 24px;
  }
`}
`;

const DesktopHero = styled.img`
  display: none;
  ${({ theme }) => `
  ${theme.breakPoints.tablet} {
    display: block;
  }
  `}
`;

const MobileHero = styled.img`
display: block;
${({ theme }) => `
${theme.breakPoints.tablet} {
  display: none;
}
`}
`;

const FeatureHero = ({ title, imageDesktop, imageMobile }) => {
  return (
    <MainContainer>
      <Title>{title}</Title>
      <div>
        <DesktopHero src={imageDesktop} />
      </div>

      <MobileHero src={imageMobile} />
    </MainContainer>
  )
}

export default FeatureHero;
